import React from "react";
import CeWrapper from "../../global/CeWrapper";
import AdGlare from "../../../../../components/AdGlare/AdGlare";
import CeDataGetter from "../../../libs/CeDataGetter";

const AdGlareCe = ({ ceData }) => {
  return (
    <CeWrapper ceData={ceData} renderHeader={false}>
      <AdGlare
        zoneId={ceData.contentParts?.adglareZoneId}
        zoneIdMobileOverride={ceData.contentParts?.adglareMobileZoneId}
        uniqueId={CeDataGetter.getUid(ceData)}
      />
    </CeWrapper>
  );
};

export default AdGlareCe;
